import { GithubOutlined, LogoutOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Button, Flex, Tooltip, Typography } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { userApi } from 'entities/user/api/user.api'
import { NavLink, useNavigate } from 'react-router-dom'
import { FeedIcon } from 'shared/icons/FeedIcon'
import { Price } from 'shared/ui/Price'
import { ProfileImage } from 'shared/ui/ProfileImage'

const HeaderW = () => {

    const isAuth = userApi.checkUserAuth()

    return (
        <Header style={{ padding: '10px 10px' }}>
            <Flex justify="space-between" align="center">
                <Tooltip placement="right" title="feed">
                    <NavLink to="/">
                        <FeedIcon style={{ fontSize: 24 }} />
                    </NavLink>
                </Tooltip>

                {
                    isAuth
                        ? <HeaderUserProfile />
                        : <HeaderLoginWithGithub />
                }
            </Flex>
        </Header>
    )
}


const HeaderUserProfile = () => {

    const { data } = useQuery({
        queryKey: ['userDataFetching'],
        queryFn: () => userApi.getUserData(),
        refetchInterval: 5000
    })

    const navigate = useNavigate()

    return <Flex align="center" gap="middle">
        <Flex align="center" gap="small">
            <Flex className="opacity50" gap="small" align="center">
                <Price amount={data?.sats.total_sats} size="small" />
                /
                <Flex align="center" gap="small">
                    <span className="opacity50">
                        <Price amount={data?.sats.reserved_sats} size="small" />
                    </span>
                    <Typography className="opacity50">reserved</Typography>
                </Flex>
            </Flex>
            <NavLink to="/profile">
                <Flex gap="small" align="center">
                    <Typography style={{ fontWeight: 'bold' }}>{data?.gitData?.login}</Typography>
                    <ProfileImage image_url={data?.gitData?.avatar_url} />
                </Flex>
            </NavLink>
        </Flex>
        <Tooltip title="Logout">
            <Button
                size="small"
                onClick={() => {
                    userApi.setAuthToken(undefined)
                    userApi.setGitHubToken(undefined)
                    navigate('/')
                }}
                icon={<LogoutOutlined />}
            />
        </Tooltip>
    </Flex>
}

const HeaderLoginWithGithub = () => {
    return (
        <NavLink to="/login">
            <Flex align="center" gap="small">
                <Typography>Login with GitHub</Typography>
                <GithubOutlined style={{ fontSize: '28px' }} />
            </Flex>
        </NavLink>
    )
}

export { HeaderW }