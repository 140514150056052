import { CopyOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Card, Col, Divider, Flex, Form, Input, notification, Popover, Row, Typography } from 'antd'
import { QRCodeCanvas } from 'qrcode.react'
import { useState } from 'react'
import { appApi } from 'shared/api/app.api'
import { Price } from 'shared/ui/Price'

const UserDepositForm = () => {

    const { mutateAsync: createDeposit, isPending } = useMutation({
        mutationFn: async (amount: number) => await appApi.wallets.depositSatsApiWalletsLightningDepositPost({
            requestBody: {
                amount_sats: amount
            }
        })
    })

    const [builded, setBuilded] = useState<undefined | { amount: number, code: string }>(undefined)
    const [api, contextHolder] = notification.useNotification()


    const createDepositHandler = async (e: any) => {
        try {
            const resp = await createDeposit(e.amount)
            setBuilded({
                amount: e.amount,
                code: resp.payment_request
            })
        } catch (e) {
            api.error({
                message: 'Error!'
            })
        }
    }

    return (
        <>
            {contextHolder}
            <Divider />
            <Row gutter={[12, 12]} style={{ width: '500px' }} align="middle">
                <Col span={12}>
                    <Form
                        onFinish={(e) => { createDepositHandler(e) }}
                        style={{ width: '100%' }}
                    >
                        <Flex vertical gap="small">
                            <Flex gap="small">
                                <Typography style={{ flexShrink: 0, marginTop: '4px' }} className="opacity50">Amount: </Typography>
                                <Form.Item
                                    name="amount"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required',
                                        }
                                    ]}
                                >
                                    <Input placeholder="100 000" />
                                </Form.Item>
                            </Flex>
                            <Typography className="opacity50">max allowed: 100,000</Typography>
                            <Button
                                htmlType="submit"
                                loading={isPending}
                                style={{ marginTop: '20px' }}
                                type="primary">
                                {
                                    builded
                                        ? 'Refresh'
                                        : 'Generate'
                                }
                            </Button>
                        </Flex>
                    </Form>
                </Col>
                <Col span={12}>
                    <Flex vertical gap="small" align="center">
                        {
                            builded
                                ? <Flex align="center" gap="small">
                                    <Typography className="opacity50">Deposit:</Typography>
                                    <Price amount={builded?.amount} />
                                </Flex>
                                : <Typography
                                    style={{ textAlign: 'center' }}
                                    className="opacity50">Let's generate a qrCode for convenience:</Typography>
                        }

                        <Card size="small" bordered={false}>
                            {
                                builded
                                    ? <QRCodeCanvas size={160} value={"lightning:" + builded?.code} />
                                    : <div style={{ width: 160, height: 160 }} />
                            }

                        </Card>
                        {
                            builded
                                ? <Flex align="center" gap="small">
                                    <Popover content={<Typography style={{ width: '200px' }}>{builded?.code}</Typography>}>
                                        <Typography className="opacity50">{builded?.code.slice(0, 7) + '...'}</Typography>
                                    </Popover>
                                    <Button onClick={() => {
                                        navigator.clipboard.writeText(builded?.code)
                                    }} icon={<CopyOutlined />}>Copy</Button>
                                </Flex>
                                : <Typography></Typography>
                        }
                    </Flex>
                </Col>
            </Row>
            <Divider />
        </>
    )
}
export { UserDepositForm }